import Vue from 'vue';
import VueSimpleSuggest from 'vue-simple-suggest';
import jobLinkFetchHeader from 'javascript/lib/job_link_fetch_header';

const BasePicker = Vue.extend({
  components: {
    VueSimpleSuggest,
  },

  props: {
    // Sets property on HTML input.
    title: {
      type: String,
      default: null,
    },
    // Sets property on HTML input.
    maxlength: {
      type: String,
      default: null,
    },
    // Sets property on HTML input.
    placeholder: {
      type: String,
      default: null,
    },
    // Sets property on HTML input.
    readonly: {
      type: Boolean,
      default: null,
    },
    // Sets property on HTML input.
    required: {
      type: Boolean,
      default: null,
    },
    // Optional (''). Initial value to use for the component.
    inputValue: {
      type: String,
      default: '',
    },
    // Optional (true). Controls if input is hidden.
    inputShown: {
      type: Boolean,
      default: true,
    },
    // Optional (''). Overrides default display behavior by updating label
    // elsewhere on the page (direct DOM, for non-Vue rendered markup).
    labelExternalId: {
      type: String,
      default: '',
    },
    // Optional (true). Controls if label is displayed after the input.
    showLabel: {
      type: Boolean,
      default: true,
    },
    // Optional ('code'). Controls how selection is mapped to the entry box.
    textMapping: {
      type: String,
      default: 'code',
    },
    valueMode: {
      type: String,
      default: 'id',
    },
    // Optional ({}). Value object (preferred) or JSON string to additionally pass to the
    // API the auto-complete component uses. Can be used to apply specific scopes
    // or otherwise control the results.
    apiArguments: {
      validator(val) {
        return val !== null;
      },
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      chosen: null,
      cache: {},
      text: '',
      focused: false,
      loading: 0,
      justSelected: false,
      origText: null,
      // matches is intentionally non-reactive
    };
  },

  computed: {
    shownLabel() {
      return this.chosen && this.showLabel && this.labelExternalId === '';
    },
    fieldValue() {
      const {
        chosen,
        valueMode,
      } = this;

      if (chosen == null) return '';
      return chosen[valueMode] || chosen.id;
    },
    pickerName() {
      return `${this.inputName}_picker`;
    },
    pickerId() {
      return `${this.inputId}_picker`;
    },
    apiData() {
      const { apiArguments } = this;
      let obj = apiArguments || {};

      if (Array.isArray(obj)) {
        // eslint-disable-next-line no-console
        console.warn(
          'API arguments should be supplied as an object or string, not array',
        );
        obj = {};
      } else if (typeof apiArguments === 'string') {
        obj = JSON.parse(apiArguments);
      }

      return { ...obj, name: this.text.trim(), original: this.origText };
    },
  },

  beforeMount() {
    this.setupFromDefaultInput();
  },


  mounted() {
    this.shiftLabelFor();
  },

  methods: {
    shiftLabelFor() {
      const el = $(`label[for="${this.inputId}"]`);
      const pickerLabel = this.pickerId;
      if (el.length) {
        el.attr('for', pickerLabel);
      }
    },

    populateLookupData() {
      return this.acquireLookupData(null).then(arr => arr.filter(d => d.visible !== false));
    },

    fetchOrCacheLookup(requestBody, fetcher) {
      const CACHE_TIMEOUT = 60 * 10000; // 10 minutes
      const entry = this.cache[requestBody];

      if (entry != null && entry.at > Date.now()) {
        // console.info('Cache Hit', requestBody);
        return Promise.resolve(entry.results);
      }

      // console.info('Cache Miss', requestBody);
      return fetcher().then(response => response.json()).then((data) => {
        this.cache[requestBody] = { at: Date.now() + CACHE_TIMEOUT, results: data };
        return data;
      });
    },

    acquireLookupData(bodyData = null) {
      this.loading += 1;
      this.matches = [];

      const requestData = Object.assign({ mode: 'vue' }, bodyData || this.apiData);
      const requestBody = JSON.stringify(requestData);

      return this.fetchOrCacheLookup(
        requestBody,
        () => fetch(this.apiUrl(), {
          method: 'post',
          credentials: 'same-origin',
          headers: jobLinkFetchHeader(),
          body: requestBody,
        }),
      ).then(data => this.processAcquiredLookupData(data)).catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Autocomplete fetch failed', error);
        return this.processAcquiredLookupData([]);
      });
    },

    processAcquiredLookupData(data) {
      this.matches = data;
      this.loading -= 1;

      // console.info(
      //   'processing suggestion list data',
      //   data,
      //   { loading: this.loading, focused: this.focused },
      // );

      // Focused users are given a list of suggestions.
      if (this.focused) { return data; }

      // Users that tabbed out already aren't, but the
      // picker tries to find a matching occupation.
      if (this.chosen == null) { this.findBestMatch(); }

      return [];
    },

    onSuggestSelect(result) {
      // Replace search entry with code selected.
      // console.log('select', result);
      if (result == null) return;

      this.text = result[this.textMapping];
      this.justSelected = true;

      // Prevent bug where list opens again after selecting item
      this.$nextTick(() => {
        this.$refs.suggestInput.blur();
      });
    },

    onInputEvent(entry) {
      // console.log('input', entry);
      if (entry === '') {
        // Clear match if input cleared always.
        this.matches = [];
        this.chosen = null;
      } else if (this.justSelected) {
        // Fired after selection, so unset flag and ignore.
        this.justSelected = false;
      } else {
        // User changed input since or without list selection.
        this.chosen = null;
      }
    },

    onInputFocus() {
      this.focused = true;
      this.$emit('focus');
    },

    onInputBlur() {
      this.focused = false;
      if (this.chosen != null) return;

      // If the user types an entry manually and tabs out,
      // this ensures that a match is set. It is not permitted
      // to use a code the system doesn't support.
      this.findBestMatch();

      this.$emit('blur');
    },

    foundMatch(match) {
      this.chosen = match;

      const text = match[this.textMapping];
      if (this.origText === null) { this.origText = text; }

      this.text = text;
    },

    findFirstMatch() {
      const match = this.matches ? this.matches[0] : null;

      // console.log('findFirstMatch', match);
      if (match) this.foundMatch(match);
    },

    findBestMatch() {
      const {
        matches,
        text,
        textMapping,
      } = this;

      if (text === '') return;

      const match = (matches || []).find(m => m[textMapping] === text);

      if (match) {
        // Exact code match found.
        this.foundMatch(match);
      } else {
        // Closest fuzzy match available.
        this.findFirstMatch();
      }
    },

    setupFromDefaultInput() {
      const {
        inputValue,
        valueMode,
      } = this;

      if (inputValue === '' || inputValue === null) return;

      let bodyData = null;
      const forId = valueMode === 'id';

      if (forId) {
        bodyData = { id: inputValue.trim() };
      } else {
        this.origText = inputValue.trim();
        this.text = inputValue.trim();
      }

      this.acquireLookupData(bodyData).then(() => {
        if (forId) {
          this.findFirstMatch();
        } else {
          this.findBestMatch();
        }
      });
    },

    setExternalText(id, nameOfId, prop, val) {
      if (id == null || id === '') return;

      const elem = document.getElementById(id);
      let content = '';
      if (val) {
        if (Array.isArray(prop)) {
          const key = prop.find(p => val[p] != null);
          if (key) content = val[key];
        } else {
          content = val[prop];
        }
      }
      if (content == null) content = '';

      if (elem) {
        elem.textContent = content;
      } else {
        // eslint-disable-next-line no-console
        console.warn(
          `${nameOfId} not found`,
          id,
          'to update with',
          content,
        );
      }
    },
  },
});

export default BasePicker;
