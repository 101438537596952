<template>
  <div>
    <vue-simple-suggest
      v-model="chosen"
      value-attribute="key"
      display-attribute="display"
      class="field-hint--search"
      :list="populateLookupData"
      :max-suggestions="25"
      :min-length="1"
      :debounce="500"
      :filter-by-query="false"
      mode="select"
      @select="onSuggestSelect"
      @input="onInputEvent"
      @focus="onInputFocus"
      @blur="onInputBlur"
    >
      <input
        v-show="inputShown"
        :id="pickerId"
        ref="suggestInput"
        v-model="text"
        :aria-describedby="pickerId + '-onet-hint ' + pickerId + '-onet-details'"
        :name="pickerName"
        :maxlength="maxlength"
        :placeholder="placeholder"
        :readonly="readonly"
        :aria-required="required"
        :title="title"
        type="text"
        autocomplete="off"
      />
      <div
        v-if="loading"
        slot="misc-item-below"
        class="misc-item"
      >
        <span>Working on it&hellip;</span>
      </div>
      <div
        slot="suggestion-item"
        slot-scope="{ suggestion }"
      >
        <span class="vue-simple-picker-label">{{ suggestion.label }}</span>
        <span class="vue-simple-picker-code">{{ suggestion.code }}</span>
      </div>
    </vue-simple-suggest>
    <div
      v-show="inputShown"
      :id="pickerId + '-onet-hint'"
      class="hint"
    >
      Enter a keyword or O*NET code for the occupation.
      <a
        href="https://www.onetonline.org"
        title="Opens in a new window"
        data-tooltip="true"
        target="_blank"
      >
        Search for O*NET Codes
        <span
          class="icon-general_link_external"
          aria-hidden="true"
        ></span>
      </a>
    </div>
    <div
      v-if="shownLabel || shownDescription"
      :id="pickerId + '-onet-details'"
      class="vue-simple-picker__description"
    >
      <span
        v-if="shownLabel"
        class="vue-simple-picker__short-label"
      >
        {{ chosen.shortLabel || chosen.label }}
      </span>
      <span
        v-if="shownDescription"
        class="vue-simple-picker__code"
      >
        {{ chosen.code }}
      </span>
      <span
        v-if="shownDescription"
        class="vue-simple-picker__label-description"
      >
        {{ chosen.description }}
      </span>
    </div>

    <input
      :id="inputId"
      :value="fieldValue"
      :name="inputName"
      type="hidden"
    />
    <p v-if="debug">
      <strong>Hidden Input:</strong> {{ inputName }}={{ fieldValue }}<br />
      <strong>Initial Value:</strong> {{ inputValue }}
    </p>
  </div>
</template>

<script>
import 'vue-simple-suggest/dist/styles.css';
import BasePicker from './onet_naics_shared';

export default BasePicker.extend({
  methods: {
    apiUrl() {
      return '/lookup/occupation_lookups.json';
    },
  },
});
</script>
