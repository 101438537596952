export default function jobLinkFetchHeader(otherHeaders) {
  const metaTokenElem = document.querySelector('meta[name="csrf-token"]');
  const authenticityToken = metaTokenElem == null ? null : metaTokenElem.getAttribute('content');
  const result = {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    ...(otherHeaders || {}),
  };

  if (authenticityToken !== null) {
    result['X-CSRF-TOKEN'] = authenticityToken;
  }

  return result;
}
