import BasePicker from './base_picker';

export default BasePicker.extend({
  props: {
    // Required. ID to assign hidden input. Visible input is suffixed with _picker.
    inputId: {
      type: String,
      default: 'vue-simple-picker_picker_no_id',
    },
    // Required. Name to assign hidden input. Visible input is suffixed with _picker.
    inputName: {
      type: String,
      default: 'vue-simple-picker_picker_no_name',
    },
    // displays both code and title in input.
    textMapping: {
      type: String,
      default: 'display',
    },
    // Optional ('id'). Controls how the component's value (and hidden value) behaves. Supports:
    // * code - Stores the code as the value (e.g. 11-1011.00).
    // * id - Stores the underlying database identifier.
    valueMode: {
      validator(val) {
        return ['code', 'id'].includes(val);
      },
      default: 'id',
    },
    // Optional (''). Overrides default display behavior by updating desc.
    // elsewhere on the page (direct DOM, for non-Vue rendered markup).
    descExternalId: {
      type: String,
      default: '',
    },
    // Optional (false). Controls display of debug information.
    debug: {
      type: Boolean,
      default: false,
    },
    // Optional (false). Controls if description is displayed after the input.
    showDescription: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    shownDescription() {
      return this.chosen && this.showDescription && this.descExternalId === '';
    },
  },
  watch: {
    chosen(val) {
      const {
        descExternalId,
        labelExternalId,
        fieldValue,
      } = this;

      this.setExternalText(descExternalId, 'descExternalId', 'description', val);
      this.setExternalText(labelExternalId, 'labelExternalId', 'label', val);
      this.$emit('change', fieldValue);
    },
  },
});
