<template>
  <div>
    <vue-simple-suggest
      v-model="chosen"
      value-attribute="key"
      display-attribute="display"
      class="field-hint--search"
      :list="populateLookupData"
      :max-suggestions="20"
      :min-length="1"
      :debounce="500"
      :filter-by-query="false"
      mode="select"
      @select="onSuggestSelect"
      @input="onInputEvent"
      @focus="onInputFocus"
      @blur="onInputBlur"
    >
      <input
        v-show="inputShown"
        :id="pickerId"
        ref="suggestInput"
        v-model="text"
        :aria-describedBy="pickerId + '-naics-hint' + pickerId + '-naics-details'"
        :name="pickerName"
        :maxlength="maxlength"
        :placeholder="placeholder"
        :readonly="readonly"
        :aria-required="required"
        :title="title"
        type="search"
        autocomplete="off"
      />
      <div
        v-if="loading"
        slot="misc-item-below"
        class="misc-item"
      >
        <span>Working on it&hellip;</span>
      </div>
    </vue-simple-suggest>
    <div
      v-show="inputShown"
      :id="pickerId + '-naics-hint'"
      class="hint"
    >
      Enter a keyword or 6-digit NAICS code for your industry.
      <a
        href="https://www.naics.com/search/"
        data-tooltip="true"
        title="Opens in a new window"
        target="_blank"
      >
        Search for NAICS Codes
        <span
          class="icon-general_link_external"
          aria-hidden="true"
        ></span>
      </a>
    </div>
    <!-- TODO: Ticket 42400 Remove hidden class once naics description has been added to JSON -->
    <div
      v-if="shownLabel || shownDescription"
      :id="pickerId + '-naics-details'"
      class="vue-simple-picker__description"
    >
      <span
        v-if="shownLabel"
        class="vue-simple-picker__short-label"
      >
        {{ chosen.shortLabel || chosen.label }}
      </span>
      <span
        v-if="shownDescription"
        class="vue-simple-picker__code"
      >
        {{ chosen.code }}
      </span>
      <span
        v-if="shownDescription"
        class="vue-simple-picker__label-description"
      >
        {{ chosen.description }}
      </span>
    </div>

    <input
      :id="inputId"
      :value="fieldValue"
      :name="inputName"
      type="hidden"
    />
    <p v-if="debug">
      <strong>Hidden Input:</strong> {{ inputName }}={{ fieldValue }}<br />
      <strong>Initial Value:</strong> {{ inputValue }}
    </p>
  </div>
</template>

<script>
import 'vue-simple-suggest/dist/styles.css';
import BasePicker from './onet_naics_shared';

export default BasePicker.extend({
  props: {
    valueMode: {
      validator(val) {
        return ['code', 'id'].includes(val);
      },
      default: 'code',
    },
  },
  methods: {
    apiUrl() {
      return '/lookup/industry_lookups.json';
    },
  },
});
</script>
