<template>
  <div :class="wrapperClassNames">
    <select
      :id="id"
      :class="selectClassNames"
      :name="name"
      :disabled="disabled"
      :required="required"
      :aria-required="ariaRequired"
      :aria-invalid="ariaInvalid"
      :aria-describedby="ariaDescribedby"
    ></select>
  </div>
</template>

<script>
// See [https://select2.org/configuration/options-api]
// We use the SelectWoo fork, but the options are the same.

// Based off of [https://github.com/godbasin/vue-select2/blob/npm-publish-code/src/Select2.vue]
// Commit c02299cc1701dd5c00ed3b1d24c7c919cfe1ab85 (March 13, 2021).
// Made numerous changes to support various expected attributes and to make ESLint happy.
export default {
  name: 'Select2',
  model: {
    event: 'change',
    prop: 'value',
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: null,
    },
    ariaRequired: {
      type: String,
      default: null,
    },
    ariaDescribedby: {
      type: String,
      default: null,
    },
    ariaInvalid: {
      type: Boolean,
      default: null,
    },
    inputClasses: {
      type: [String, Object],
      default: null,
    },
    maxlength: {
      type: Number,
      default: null,
    },
    wrapperClass: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    settings: {
      type: Object,
      default: () => {},
    },
    // eslint-disable-next-line vue/require-default-prop
    value: null,
  },

  data() {
    return {
      select2: null,
    };
  },

  computed: {
    effectiveSettings() {
      const { settings, value } = this;
      if (settings.tags !== true) return settings;
      if (value == null || value === '') return settings;

      const newTags = Array.isArray(value) ? value : [value];
      return { ...settings, tags: newTags };
    },

    wrapperClassNames() {
      return ['select2-vue', this.wrapperClass];
    },

    selectClassNames() {
      const { inputClasses } = this;

      if (Array.isArray(inputClasses)) {
        return ['form-control', ...inputClasses];
      }
      if (inputClasses != null) {
        return ['form-control', inputClasses];
      }

      return ['form-control'];
    },
  },

  watch: {
    options(val) {
      this.setOption(val);
    },
    value(val) {
      this.setValue(val);
    },
  },

  beforeDestroy() {
    this.select2.select2('destroy');
  },

  mounted() {
    // eslint-disable-next-line no-unused-vars
    const $sel = $(this.$el).find('select');
    const {
      settings,
      options,
      placeholder,
      maxlength,
    } = this;

    this.select2 = $sel
      .select2({
        placeholder,
        ...settings,
        data: options,
      })
      .on('select2:select select2:unselect', (ev) => {
        this.$emit('change', this.select2.val());
        this.$emit('select', ev.params.data);
      })
      .on('select2:closing', (ev) => {
        this.$emit('closing', ev);
      })
      .on('select2:close', (ev) => {
        this.$emit('close', ev);
      })
      .on('select2:opening', (ev) => {
        this.$emit('opening', ev);
      })
      .on('select2:open', (ev) => {
        if (maxlength != null) {
          this.setMaxLength($sel, maxlength);
        }

        this.$emit('open', ev);
      })
      .on('select2:clearing', (ev) => {
        this.$emit('clearing', ev);
      })
      .on('select2:clear', (ev) => {
        this.$emit('clear', ev);
      });

    this.setValue(this.value);
  },

  methods: {
    setMaxLength($sel, maxLength) {
      const s = $sel.data('select2');
      if (s == null || s.$container == null) { return; }

      s.$container.find('.select2-search__field').attr(
        'maxlength', maxLength,
      );
    },

    setOption(val = []) {
      const { select2, settings, placeholder } = this;

      select2.empty();
      select2.select2({
        placeholder,
        ...settings,
        data: val,
      });
      this.setValue(this.value);
    },

    setValue(val) {
      const { select2 } = this;

      if (Array.isArray(val)) {
        select2.val([...val]);
      } else {
        select2.val([val]);
      }

      select2.trigger('change');
    },
  },
};
</script>
